import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Connect({ data }) {
  return (
    <Layout>
      <Seo title="Chad Rieder | Connect" description="Contact Minneapolis photographer Chad Rieder by email or phone for work inquiries, photo licensing, and photography prints." />
      <div className="max-inner">
        <div className="contact-inner">
          <div className="contact-flex-inner">
            <div className="col col-1">
              <h1 className="hdg-1">Connect</h1>
            </div>
            <div className="col col-2">
              <p>If you're interested in working with me, or you just want to say hello, let's connect.</p>
              <ul className="contact-list">
                <li>
                    <a href="mailto:chad@chadrieder.com" target="_blank" rel="noreferrer">chad@chadrieder.com</a>
                </li>
                <li>
                    <a href="tel:1-612-636-5113">612.636.5113</a>
                </li>
                <li>
                    <a href="https://www.instagram.com/chadrieder/"  target="_blank" rel="noreferrer">Instagram</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
